<template>
  <div class="product-page">
    <v-row no-gutters>
      <v-col cols="12" class="page-header d-flex py-3 align-center">
        <span class="page-title">{{ $t('Upsell offers') }}</span>
        <v-spacer></v-spacer>
        <v-col class="d-flex align-center">
          <v-spacer></v-spacer>
          <!-- <v-btn class="" outlined color="primary"><i class="mr-1 fas fa-file-export"></i>{{ $t('Export') }} </v-btn> -->
          <!-- <v-btn class="mx-1" outlined color="primary"><i class="fas fa-download mr-1"></i>{{ $t('Import') }} </v-btn> -->
          <v-btn color="primary" @click="$router.push({ name: 'website.apps.upsell.create' })"
            ><i class="fa fa-plus mr-1"></i>{{ $t('Create offer') }}</v-btn
          >
        </v-col>
      </v-col>
      <v-col cols="12" class="d-flex">
        <span
          >Showing more potential products after your customers add a product to cart or successfully check out, Upsell
          offer has been proven that it may boost conversion rate to 16%. See how it looks or watch video to know how it
          works.
        </span>
      </v-col>
    </v-row>
    <Offer />
  </div>
</template>

<script>
import Offer from './components/Offer';
export default {
  components: {
    Offer,
  },
  data() {
    return {
      ids: [],
    };
  },
};
</script>
